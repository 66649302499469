<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img src="/img/icons/logo.png" contain height="200" />
      </v-col>
      <v-sheet elevation="0" class="col">
        <h1 class="display-2 font-weight-bold mb-3">Welcome to Tasky</h1>

        <p class="subheading text-body-2 font-font-weight-thin">
          This is the alpha version of tasky
          <br />please check the app regularly to see the latest version and new
          features.
        </p>
      </v-sheet>

      <v-card outlined class="col-12">
        <h2 class="headline font-weight-bold">But What is tasky?</h2>
        <p>
          tasky is a simple task manager for any group of any size and
          professions to help the users have an efficient way of coperation and
          leaders keep track of the daily tasks of their teams
        </p>
        <p>or even a personal task manager for your own improvement</p>
        <p>
          wheter you use it for giant teams or your own to-do list is up to you
        </p>
        <p class="text-body-1 font-weight-bold">
          and regardless tasky is here for you
        </p>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
    importantLinks: [
      {
        text: "Documentation",
        href: "https://vuetifyjs.com",
      },
      {
        text: "Chat",
        href: "https://community.vuetifyjs.com",
      },
      {
        text: "Made with Vuetify",
        href: "https://madewithvuejs.com/vuetify",
      },
      {
        text: "Twitter",
        href: "https://twitter.com/vuetifyjs",
      },
      {
        text: "Articles",
        href: "https://medium.com/vuetify",
      },
    ],
    whatsNext: [
      {
        text: "Explore components",
        href: "https://vuetifyjs.com/components/api-explorer",
      },
      {
        text: "Select a layout",
        href: "https://vuetifyjs.com/getting-started/pre-made-layouts",
      },
      {
        text: "Frequently Asked Questions",
        href:
          "https://vuetifyjs.com/getting-started/frequently-asked-questions",
      },
    ],
  }),
};
</script>
